import "./index.scss";

import { useEffect, useState } from "react";

import { getUser, verifyPartnerAccount } from "api";
import { logo } from "assets";
import { Avatar, PlatformSwitcher } from "components";
import { useAppSessionState, useLoadingState } from "middleware";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthService, getLandingPage } from "services";
import { ROUTES } from "variables";

export function Header() {
  const { appSession } = useAppSessionState();
  const { setLoading } = useLoadingState();

  const [isLogout, setIsLogout] = useState(true);
  const [isPartner, setIsPartner] = useState(false);
  const [navOpen, setOpen] = useState(false);
  const [user, setUser] = useState(undefined);

  const location = useLocation();
  const navigate = useNavigate();

  const toggleNav = () => {
    setOpen(!navOpen);
  };

  const getLoginBtn = () => {
    switch (location.pathname) {
      case ROUTES.LOGIN:
        return (
          <Button
            className="btn btn-header"
            onClick={() => navigate(ROUTES.REGISTER)}
          >
            Register
          </Button>
        );
      case ROUTES.REGISTER:
      case ROUTES.REGISTER_CONFIRM:
        return (
          <Button
            className="btn btn-header"
            onClick={() => navigate(ROUTES.LOGIN)}
          >
            Login
          </Button>
        );
      default:
        return (
          <Button
            className="btn btn-header"
            onClick={() => navigate(ROUTES.LOGIN)}
          >
            Login
          </Button>
        );
    }
  };

  useEffect(() => {
    setIsLogout(!appSession?.session);
    try {
      getUser(appSession?.session?.id).then((result) => {
        setUser(result);
      });
    } catch (err) {
      //
    }
  }, [appSession]);

  useEffect(() => {
    const checkPartner = async () => {
      let result = await verifyPartnerAccount({});
      if (!!result?.partner) {
        setIsPartner(true);
      }
    };

    checkPartner();
  }, []);

  return (
    <>
      <header className="header-dashboard" id="header">
        <div className="container">
          <div className="header-holder">
            <div className="logo">
              <Link to={ROUTES.ROOT}>
                <img src={logo} alt="Cybercert" width="100%" />
              </Link>
            </div>
            <nav id="nav">
              <a
                className={`nav-opener ${navOpen && "active"}`}
                onClick={toggleNav}
              >
                <span></span>
              </a>

              <div
                className={`nav-drop ${navOpen && "active"}`}
                onClick={toggleNav}
              >
                <div className="user-info">
                  {isLogout ? (
                    getLoginBtn()
                  ) : (
                    <>
                      {isPartner && <PlatformSwitcher />}

                      <div className="certificate-options">
                        <Link to={ROUTES.OPTIONS}>
                          <svg
                            width="28"
                            height="36"
                            viewBox="0 0 28 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 36V3.375C0 1.51102 1.52628 0 3.40909 0H23.8636C25.7464 0 27.2727 1.51102 27.2727 3.375V36L13.6364 28.125L0 36Z"
                              fill="#06364B"
                            />
                          </svg>
                        </Link>
                      </div>

                      <Link className="user-avatar" to={ROUTES.ACCOUNT}>
                        <Avatar
                          firstName={user?.firstName}
                          lastName={user?.lastName}
                        />
                      </Link>

                      <a
                        className="btn-login"
                        onClick={() => {
                          setLoading(true);
                          AuthService.signOut(() => {
                            window.location.href = `${getLandingPage()}/login?logout=true`;
                          });
                        }}
                      >
                        <svg
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.1193 8.43752H5.34396C4.87799 8.43752 4.50021 8.05974 4.50021 7.59377C4.50021 7.12779 4.87799 6.75002 5.34396 6.75002H15.1193L14.0284 5.65911C13.6989 5.3296 13.6989 4.7954 14.0284 4.46588C14.3579 4.13637 14.8921 4.13637 15.2217 4.46588L17.7528 6.99707C17.7725 7.01681 17.7912 7.03751 17.8089 7.05911C17.8166 7.06851 17.8233 7.07846 17.8305 7.08808C17.8398 7.10046 17.8494 7.11255 17.858 7.12543C17.8658 7.13719 17.8727 7.14939 17.8799 7.16143C17.8868 7.17302 17.894 7.18433 17.9004 7.19625C17.907 7.20863 17.9126 7.22134 17.9186 7.23394C17.9244 7.24626 17.9305 7.25841 17.9358 7.27101C17.9408 7.28332 17.945 7.29587 17.9494 7.30836C17.9543 7.32186 17.9595 7.33519 17.9637 7.34897C17.9674 7.36146 17.9702 7.37411 17.9733 7.38677C17.9769 7.40077 17.9808 7.41472 17.9836 7.42901C17.9865 7.44358 17.9882 7.45832 17.9903 7.473C17.9921 7.48554 17.9945 7.49792 17.9958 7.51063C17.9985 7.53786 17.9999 7.56525 17.9999 7.59259C17.9999 7.59298 18 7.59343 18 7.59382C18 7.59422 17.9999 7.59467 17.9999 7.59506C17.9999 7.62246 17.9985 7.64979 17.9958 7.67707C17.9945 7.68962 17.9922 7.70182 17.9904 7.71426C17.9883 7.72911 17.9866 7.74395 17.9836 7.75869C17.9808 7.77287 17.9769 7.78659 17.9734 7.80049C17.9702 7.81325 17.9674 7.82614 17.9636 7.83879C17.9594 7.85235 17.9543 7.86545 17.9496 7.87873C17.945 7.89144 17.9409 7.90421 17.9357 7.9167C17.9306 7.92907 17.9246 7.94094 17.9188 7.95304C17.9128 7.96592 17.907 7.97891 17.9003 7.99157C17.8941 8.0031 17.8871 8.01407 17.8804 8.02526C17.873 8.03769 17.866 8.05023 17.8579 8.06233C17.8496 8.07465 17.8405 8.08618 17.8316 8.09805C17.824 8.10823 17.8169 8.11875 17.8088 8.12865C17.7919 8.14912 17.7741 8.16875 17.7555 8.1876C17.7546 8.1885 17.7539 8.18951 17.753 8.19041L15.2217 10.7217C15.0569 10.8865 14.841 10.9689 14.6251 10.9689C14.4092 10.9688 14.1932 10.8865 14.0285 10.7218C13.699 10.3923 13.699 9.85805 14.0284 9.52854L15.1193 8.43752Z"
                            fill="#06364B"
                          />
                          <path
                            d="M0.843785 0H11.5313C11.9972 0 12.375 0.377774 12.375 0.843747V4.21874C12.375 4.68471 11.9972 5.06248 11.5313 5.06248C11.0653 5.06248 10.6875 4.68471 10.6875 4.21874V1.68749H1.68753V13.5H10.6875V10.9687C10.6875 10.5027 11.0653 10.125 11.5313 10.125C11.9972 10.125 12.375 10.5027 12.375 10.9687V14.3438C12.375 14.8097 11.9972 15.1875 11.5313 15.1875H0.843785C0.377811 15.1875 3.8147e-05 14.8097 3.8147e-05 14.3438V0.843747C3.8147e-05 0.377774 0.377811 0 0.843785 0Z"
                            fill="#06364B"
                          />
                        </svg>
                        Logout
                      </a>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
