/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://zbwlybea60.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "CertL45",
            "endpoint": "https://rrr9tblzkh.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "GAP",
            "endpoint": "https://nclc36bvn2.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "activities",
            "endpoint": "https://g4b6q19jrk.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "apiAdmin",
            "endpoint": "https://j0gqnhubal.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "attestation",
            "endpoint": "https://rdo3f385fj.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "certification",
            "endpoint": "https://t1ray4bod2.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "cybercertSSOPostSignup",
            "endpoint": "https://yyurixnmsk.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "getBadge",
            "endpoint": "https://k8n39oaura.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "issueBadge",
            "endpoint": "https://cljllf0732.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "maintenance",
            "endpoint": "https://lveacpt52a.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "organisation",
            "endpoint": "https://3q6chibu3a.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "partner",
            "endpoint": "https://dtrvhmv89c.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "privateDoc",
            "endpoint": "https://y6f3o0hl57.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "public",
            "endpoint": "https://75qfjwydl3.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "publicDoc",
            "endpoint": "https://wd7ow6y4p5.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "s3",
            "endpoint": "https://rr1qw7eezd.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "signconnect",
            "endpoint": "https://h12kczbb3h.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://lnj1hzio97.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "t2",
            "endpoint": "https://wkfy9wj98f.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "user",
            "endpoint": "https://u1qg4amdch.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://3z7mjqfxa5cgnl6fiplpx6dfqq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-edwnv7ulhndgpdak6ax2iywvji",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8a494dbd-ec28-498b-b324-2e552b78e10f",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_fZJzMzwGR",
    "aws_user_pools_web_client_id": "jjdnu2p3j36otor3plavieq7",
    "oauth": {
        "domain": "cybercertcertificatiece5d55b-ece5d55b-dev.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,http://localhost:3001/,http://localhost:3002/,http://localhost:3003/,https://cmxdev.cybercert.au/,https://demo.cybercert.au/,https://cybercert.au/,https://cmxdev.cybercert.com.au/,https://cmxdev.cybercert.ai/,https://dev.dashboard.cybercert.ai/,https://dev.partners.cybercert.ai/,https://dev.certification.cybercert.ai/",
        "redirectSignOut": "http://localhost:3000/,http://localhost:3001/,http://localhost:3002/,http://localhost:3003/,https://cmxdev.cybercert.au/,https://demo.cybercert.au/,https://cmxdev.cybercert.com.au/,https://cmxdev.cybercert.ai/,https://dev.dashboard.cybercert.ai/,https://dev.partners.cybercert.ai/,https://dev.certification.cybercert.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
