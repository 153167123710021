import "./tab-container.scss";

import { useEffect, useState } from "react";

import {
  getCertificationProgress,
  getIssuedBadge,
  getSortedByDateCertificationProgressesByABN,
  getSpecificCertAndSignedAttestationUrl,
  onUpdateProgress,
} from "api";
import { DashboardTabDesktop, DashboardTabMobile } from "components";
import { useDownloadUrlState, useLoadingState } from "middleware";
import { parseDate } from "services";

export function DashboardTabContainer({
  userData,
  progress,
  certificationInfo,
}) {
  const { setLoading } = useLoadingState();
  const [displayProgress, setDisplayProgress] = useState();
  const [currentProgress, setCurrentProgress] = useState();
  const [progresses, setProgresses] = useState();
  const { setDownloadUrl } = useDownloadUrlState();

  useEffect(() => {
    setupProgresses();
  }, []);

  useEffect(() => {
    if (!!displayProgress && !!displayProgress.ABN) {
      setLoading(true);

      setDownloadUrl({
        certificateLink: "/",
        signedDeedLink: "/",
        badges: "/",
      });
      let curProgress = currentProgress;
      curProgress = {
        ...curProgress,
        ...displayProgress,
      };
      curProgress = {
        ...curProgress,
        purchasedDate: parseDate(
          displayProgress.purchasedDate || displayProgress.createdAt,
        ),
      };
      if (!!displayProgress?.completedDate) {
        curProgress = {
          ...curProgress,
          completedDate: parseDate(displayProgress.completedDate),
        };
      }
      if (!!displayProgress?.expiredDate) {
        curProgress = {
          ...curProgress,
          expiredDate: parseDate(displayProgress.expiredDate),
        };
      }
      (async () => {
        const links = await getSpecificCertAndSignedAttestationUrl(
          displayProgress?.level,
          displayProgress?.envelopeId,
        );
        const badges = await getIssuedBadge({
          level: displayProgress?.level,
        });

        setDownloadUrl({
          certificateLink: links["CERTIFICATE"],
          signedDeedLink: links["SIGNED_ATTESTATION"],
          badges,
        });
      })();
      setCurrentProgress(curProgress);
      setTimeout(() => setLoading(false), 500);
      doScroll();
      return onUpdateProgress({
        id: displayProgress.id,
        callbackFn: (prog) => {
          setDisplayProgress(prog);
        },
      });
    }
  }, [displayProgress]);

  const setupProgresses = async () => {
    const progs =
      (await getSortedByDateCertificationProgressesByABN(progress?.ABN))
        ?.items || [];
    setProgresses(progs);
  };

  const doScroll = () => {
    document
      .getElementById("account-settings-certification-badge")
      ?.scrollIntoView({ block: "center", inline: "center" });
  };

  useEffect(() => {
    if (!!progress) {
      if (!displayProgress || !displayProgress.ABN) {
        getCertificationProgress(progress?.id)?.then((res) => {
          console.log(res);
          setDisplayProgress(res);
        });
      }
      setupProgresses();
    }
  }, [progress]);

  return (
    <div className="acc-tabs">
      <div
        className="container-md"
        id="account-certification-page-tab-container"
      >
        {/* Desktop view */}
        <DashboardTabDesktop
          userData={userData}
          progress={progress}
          displayProgress={currentProgress}
          progresses={progresses}
          setDisplayProgress={setDisplayProgress}
          currentProgressScroll={doScroll}
          certificationInfo={certificationInfo}
        />

        {/* Mobile view */}
        <DashboardTabMobile
          userData={userData}
          progress={progress}
          displayProgress={currentProgress}
          progresses={progresses}
          setDisplayProgress={setDisplayProgress}
          currentProgressScroll={doScroll}
          certificationInfo={certificationInfo}
        />
      </div>
    </div>
  );
}
